import React, { FC } from 'react';

// Style
import './Hero.css';

// Media
import logo from '../../assets/home/CommingSoonLogo.svg';
import { Box, Typography } from '@mui/material';

interface HeroProps {
  title: string;
  text?: string;
}

const Hero: FC<HeroProps> = ({ title, text }) => {
  return (
    <Box
      width="100vw"
      display="flex"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      flexDirection={'column'}
      bgcolor="#FFFFFF"
      padding={'5%'}
    >
      <Typography
        color={'#00BD57'}
        // fontFamily={'SF UI Display'}
        fontSize={'16px'}
        fontWeight={700}
        lineHeight={'normal'}
        textTransform={'uppercase'}
        textAlign={'center'}
      >
        LAUNCHING THIS FALL
      </Typography>
      <Box
        width={'384px'}
        height={'96px'}
        margin={'2% 0'}
        sx={{
          '@media (max-width: 500px)': {
            width: '300px',
          },
        }}
      >
        <img src={logo} alt="." style={{ width: '100%', height: '100%' }} />
      </Box>
      <Typography
        color={'#000'}
        // fontFamily={'SF UI Display'}
        fontSize={'24px'}
        textAlign={'center'}
        fontWeight={300}
        lineHeight={'normal'}
        // textTransform={'capitalize'}
        sx={{
          '@media (max-width: 500px)': {
            fontSize: '16px',
          },
        }}
      >
        Search, discover and review over 30,000 sign companies and suppliers.
      </Typography>
    </Box>
  );
};

export default Hero;
